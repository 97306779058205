<template>
  <div id="dashboardVehicle" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Training Track</div>
        <div class="box-S1-3 noPadding">
          <div >
            <a href="https://shiftworkpilot.theprodigygroup.co.th/manageTraining" onclick="window.open(this.href,'_blank');return false;">
              <button
                type="button"
                class="ColorTheme-S white--text theme-btn v-btn v-btn--flat v-btn--text theme--light v-size--default"
                style="float:right"
              >
               <span class="v-btn__content"><span>Edit</span></span>
              </button>
            </a>
        </div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
          </v-menu>
        </div>
      </div>
    </div>
   
    <div id="Dashboard" class="wrap-Main">
      <div class="box-S4">
        <div class="B-carddetail" >
          <table id="example" class="display "  style="width:250%" >
            <thead>
              <tr>
                <th ></th>
                <th >Name</th>
                <th></th>
                <th>Position</th>
                <th>License No.</th>
                <th>Passport</th>
                <th>Thailicense</th>
                <th>Medical</th>
                <th>OPC1</th>
                <th>OPC2</th>
                <th>Line</th>
                <th>Sim</th>
                <th>Night</th>
                <th>CRM</th>
                <th>HUET</th>
                <th>FirstAid</th>
                <th>FireFighting</th>
                <th>ESE</th>
                <th>Ground</th>
                <th>DangerousGoods</th>
                <th>CAC</th>
                <th>ICAO</th>
                <th>SMS</th>
                <th>AVSEC</th>
                <th>Check A/C</th>
                <th>Check Sim</th>
                <th>DCP</th>
                <th>GI</th>
                <th>FI</th>
                <th>SI</th>
                <th>Ca_A/C</th>
                <th>Ca_SIM</th>
                <th>DCP2</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="shift in data"
                :key="shift.pilotDataId"
                style="text-align: center"
              >
              <td>
                <img :src=[shift.personImg]  class="avatar"/>
              </td>
                <td style="text-align: left" >
                  {{ shift.fnameE }} {{shift.lnameE}}
                </td>
                 <td >
                  {{shift.pilotShortname}}
                </td>
                <td>
                  {{ shift.pilotPosition }}
                </td>
                 <td>
                  {{ shift.licenseNo }}
                </td>
                <td :style="[
                    shift.passportAlertColor == null
                      ? null
                      : { backgroundColor: shift.passportAlertColor,color:'#000000'}
                  ]">
                  {{shift.passport}}
                  </td>
                <td :style="[
                    shift.thaiLicenseAlertColor == null
                      ? null
                      : { backgroundColor: shift.thaiLicenseAlertColor,color:'#000000'}
                  ]">
                  {{shift.thaiLicense}}
                <td :style="[
                    shift.medicalAlertColor == null
                      ? null
                      : { backgroundColor: shift.medicalAlertColor,color:'#000000'}
                  ]">
                {{shift.medical}}
                </td>
                 <td :style="[
                    shift.opc1AlertColor == null
                      ? null
                      : { backgroundColor: shift.opc1AlertColor,color:'#000000'}
                  ]">
                  {{shift.opc1}}
                </td>
                <td :style="[
                    shift.opc2AlertColor == null
                      ? null
                      : { backgroundColor: shift.opc2AlertColor,color:'#000000'}
                  ]">
                  {{shift.opc2}}
                </td>
                 <td :style="[
                    shift.lineAlertColor == null
                      ? null
                      : { backgroundColor: shift.lineAlertColor,color:'#000000'}
                  ]">
                  {{shift.line}}
                </td>
                 <td :style="[
                    shift.simAlertColor == null
                      ? null
                      : { backgroundColor: shift.simAlertColor,color:'#000000'}
                  ]">
                  {{shift.sim}}
                </td>
                 <td :style="[
                    shift.nightAlertColor == null
                      ? null
                      : { backgroundColor: shift.nightAlertColor,color:'#000000'}
                  ]">
                  {{shift.night}}
                </td>
                 <td :style="[
                    shift.crmAlertColor == null
                      ? null
                      : { backgroundColor: shift.crmAlertColor,color:'#000000'}
                  ]">
                  {{shift.crm}}
                </td>
                 <td :style="[
                    shift.huetAlertColor == null
                      ? null
                      : { backgroundColor: shift.huetAlertColor,color:'#000000'}
                  ]">
                  {{shift.huet}}
                </td>
                 <td :style="[
                    shift.firstAidAlertColor == null
                      ? null
                      : { backgroundColor: shift.firstAidAlertColor,color:'#000000'}
                  ]">
                  {{shift.firstAid}}
                </td>
                 <td :style="[
                    shift.fireFightingAlertColor == null
                      ? null
                      : { backgroundColor: shift.fireFightingAlertColor,color:'#000000'}
                  ]">
                  {{shift.fireFighting}}
                </td>
                 <td :style="[
                    shift.eseTraingAlertColor == null
                      ? null
                      : { backgroundColor: shift.eseTraingAlertColor,color:'#000000'}
                  ]">
                  {{shift.eseTraing}}
                </td>
                 <td :style="[
                    shift.groundAlertColor == null
                      ? null
                      : { backgroundColor: shift.groundAlertColor,color:'#000000'}
                  ]">
                  {{shift.ground}}
                </td>
                 <td :style="[
                    shift.dangerousGoodsAlertColor == null
                      ? null
                      : { backgroundColor: shift.dangerousGoodsAlertColor,color:'#000000'}
                  ]">
                  {{shift.dangerousGoods}}
                </td>
                 <td :style="[
                    shift.cacAlertColor == null
                      ? null
                      : { backgroundColor: shift.cacAlertColor,color:'#000000'}
                  ]">
                  {{shift.cac}}
                </td>
                 <td :style="[
                    shift.icaoAlertColor == null
                      ? null
                      : { backgroundColor: shift.icaoAlertColor,color:'#000000'}
                  ]">
                  {{shift.icao}}
                </td>
                 <td :style="[
                    shift.smsAlertColor == null
                      ? null
                      : { backgroundColor: shift.smsAlertColor,color:'#000000'}
                  ]">
                  {{shift.sms}}
                </td>
                 <td :style="[
                    shift.avsecAlertColor == null
                      ? null
                      : { backgroundColor: shift.avsecAlertColor,color:'#000000'}
                  ]">
                  {{shift.avsec}}
                </td>
                 <td :style="[
                    shift.checkAircraftAlertColor == null
                      ? null
                      : { backgroundColor: shift.checkAircraftAlertColor,color:'#000000'}
                  ]">
                  {{shift.checkAircraft}}
                </td>
                 <td :style="[
                    shift.checkSimAlertColor == null
                      ? null
                      : { backgroundColor: shift.checkSimAlertColor,color:'#000000'}
                  ]">
                  {{shift.checkSim}}
                </td>
                 <td :style="[
                    shift.dcpAlertColor == null
                      ? null
                      : { backgroundColor: shift.dcpAlertColor,color:'#000000'}
                  ]">
                  {{shift.dcp}}
                </td>
                 <td :style="[
                    shift.giAlertColor == null
                      ? null
                      : { backgroundColor: shift.giAlertColor,color:'#000000'}
                  ]">
                  {{shift.gi}}
                </td>
                 <td :style="[
                    shift.fiAlertColor == null
                      ? null
                      : { backgroundColor: shift.fiAlertColor,color:'#000000'}
                  ]">
                  {{shift.fi}}
                </td>
                 <td :style="[
                    shift.siAlertColor == null
                      ? null
                      : { backgroundColor: shift.siAlertColor,color:'#000000'}
                  ]">
                  {{shift.si}}
                </td>
                 <td :style="[
                    shift.caaAlertColor == null
                      ? null
                      : { backgroundColor: shift.caaAlertColor,color:'#000000'}
                  ]">
                  {{shift.caa}}
                </td>
                 <td :style="[
                    shift.casAlertColor == null
                      ? null
                      : { backgroundColor: shift.casAlertColor,color:'#000000'}
                  ]">
                  {{shift.cas}}
                </td>
                 <td :style="[
                    shift.dcp2AlertColor == null
                      ? null
                      : { backgroundColor: shift.dcp2AlertColor,color:'#000000'}
                  ]">
                  {{shift.dcp2}}
                </td>

              </tr>
            </tbody>
            <tfoot>
              <tr>
              <th colspan="5">warning</th>
              <th>200 day</th>
              <th>90 Days</th>
              <th>90Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>30 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>90 Days</th>
              <th>240 Days</th>
              <th>60 Days</th>
              <th>60 Days</th>
              <th>180 Days</th>
              <th>180 Days</th>
              <th>180 Days</th>
              <th>180 Days</th>
              <th>180 Days</th>
              <th>180 Days</th>
              <th>365 Days</th>
              <th>365 Days</th>
              <th>1M</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientVan from "../plugins/feathers-client";

export default {
  data: () => ({
    days: [],
    dateSelect: new Date().toISOString().substr(0, 7),
    picker: {},
    data: [],
    summary: [],
    checkcase: 0,
    isheadder: true,
    isdetail: true,
    countdayinmonth: 0,
    count: 0,
    shift: [],
  }),
  components: {},
  async mounted() {
    this.picker = this.dateSelect;
    await this.ReloadData();
    await $('#example').DataTable( {
        header: true,
        footer: true,
        scrollX: true,
        pageLength: this.data.length,
         
     });
  },
  methods: {
    async Render(Select) {
      await this.ReloadData();
    },
    async ReloadData() {
      let shiftData = await this.$ProdigyFlyService.GetTraningExpireDate();
     this.data = shiftData.data;
    },
    myFunction() {},
  },
};
</script>

<style>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 2px;
}
th, td { white-space: nowrap; }
.paging_simple_numbers{
    visibility: hidden;
}
.dataTables_length{
    visibility: hidden;
}
.dataTables_filter{
    visibility: hidden;
}
.dataTables_info{
   visibility: hidden;
}
.profile {
  width: 100%;
  flex: auto;
}
div.dataTables_wrapper {
        width: 75vw;
        height: 100%;
        margin: 0 auto;
    }
tbody tr:hover {
  background-color: #555555;
cursor: move; 
cursor: grab;
cursor: -moz-grab;
cursor: -webkit-grab;
}

span.small {
  font-size: smaller;
  padding-left: 5px;
  color: #cccccc;
}
</style>